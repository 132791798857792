<template>
  <!-- BEGIN: Content-->
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ convo.id ? 'Edit' : 'Add' }} Call{{ convo.id ? `: ${convo.title}` : '' }}<br>
              </h2>
              <p><small><span class="pe-2">{{ areas.join(', ') }}</span>Next step: <i data-feather="calendar" /> {{ convo.next_step ? convo.next_step.start_date : '---' }} - {{ convo.next_step ? convo.next_step.name : '---' }}</small></p>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back to  Call list"
              @click="$router.push({name: 'admin.calls.senior.index'})"
            ><i data-feather="chevron-left" /></a>

            <div class="btn-group dropup dropdown-icon-wrapper">
              <b-dropdown
                variant="link"
                no-caret
                toggle-class="p-1"
                right
              >
                <template #button-content>
                  <i data-feather="more-vertical" />
                </template>
                <div
                  class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                >
                  <b-dropdown-item
                    :href="$router.resolve({ name: 'admin.calls.senior.edit', params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >
                    Edit Call
                  </b-dropdown-item>
                  <b-dropdown-item
                    :href="$router.resolve({ name: 'admin.calls.senior.edit', params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >
                    View  Call dates
                  </b-dropdown-item>
                  <div class="dropdown-divider" />
                  <b-dropdown-item
                    :href="$router.resolve({ name: 'admin.calls.senior.groups', params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >
                    Evaluator groups
                  </b-dropdown-item>
                  <div class="dropdown-divider" />
                  <b-dropdown-item
                    :href="$router.resolve({ name: 'admin.calls.senior.pending', params: { id: convo.id }, query: { actAs: $route.query.actAs } }).href"
                    target="_blank"
                  >
                    Pending evaluators
                  </b-dropdown-item>
                </div>
              </b-dropdown>
            </div>

            <a
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title=" call settings"
            ><i data-feather="settings" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card card--senior-call-header">
              <div class="card-body ">
                <div class="row">
                  <div class="col-sm-6 d-flex align-items-center justify-content-between">
                    <div class="card--senior-call-section">
                      <strong>{{ convo.stats ? (convo.stats.applicants - convo.stats.noelegibles) : 0 }}/{{ convo.stats ? convo.stats.applicants : 0 }} </strong>Applicants
                    </div>
                    <div class="card--senior-call-section">
                      <strong>{{ convo.evaluators ? convo.evaluators.length : 0 }} </strong>Evaluators
                    </div>
                    <div class="card--senior-call-section">
                      <strong>{{ convo.feedback_editors ? convo.feedback_editors.length : 0 }} </strong>Feedback editors
                    </div>
                  </div>

                  <div class="col-sm-6 card--senior-call-change-status d-flex align-items-center">
                    <div class="avatar bg-light-warning me-1">
                      <div class="avatar-content">
                        <i
                          class="avatar-icon"
                          data-feather="git-pull-request"
                        />
                      </div>
                    </div>
                    <div class="flex-grow-1">
                      <label
                        for=""
                        class="form-label"
                      >Current status <em>This status is show by default</em></label>
                      <select
                        id=""
                        v-model="loggedUser.call_status"
                        name=""
                        class="form-select"
                        @change="updateCallStatus"
                      >
                        <option value="0">
                          All applicants
                        </option>
                        <option value="1">
                          Not eligible
                        </option>
                        <option value="997">
                          Retrieves
                        </option>
                        <option value="4">
                          1st round
                        </option>
                        <option value="5">
                          2nd round
                        </option>
                        <option value="6">
                          Ranked
                        </option>
                        <option value="7">
                          Acta
                        </option>
                        <option value="8">
                          Pdf evaluators
                        </option>
                        <option value="995">
                          Offered
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <b-tabs>
              <b-tab
                :active="activeTab == 0"
                @click="clickedTab = 0"
              >
                <template #title>
                  All applicants
                </template>

                <AllApplicants v-if="activeTab == 0" />
              </b-tab>
              <b-tab
                :active="activeTab == 1"
                @click="clickedTab = 1"
              >
                <template #title>
                  Not ellegible
                </template>
                <NotElegible
                  v-if="activeTab == 1"
                  :status="3"
                />
              </b-tab>
              <b-tab
                :active="activeTab == 4"
                @click="clickedTab = 4"
              >
                <template #title>
                  1st round
                </template>
                <AllApplicants
                  v-if="activeTab == 4"
                  :status="4"
                />
              </b-tab>
              <b-tab
                :active="activeTab == 997"
                @click="clickedTab = 997"
              >
                <template #title>
                  Retrieves
                </template>
                <Retrieves
                  v-if="activeTab == 997"
                />
              </b-tab>
              <b-tab
                :active="activeTab == 5"
                @click="clickedTab = 5"
              >
                <template #title>
                  2nd round
                </template>
                <AllApplicants
                  v-if="activeTab == 5"
                  :status="5"
                />
              </b-tab>
              <b-tab
                :active="activeTab == 6"
                @click="clickedTab = 6"
              >
                <template #title>
                  Ranked
                </template>
                <Rankeds
                  v-if="activeTab == 6"
                />
              </b-tab>
              <b-tab
                :active="activeTab == 7"
                @click="clickedTab = 7"
              >
                <template #title>
                  Acta
                </template>
                <Acta v-if="activeTab == 7" />
              </b-tab>
              <b-tab
                :active="activeTab == 8"
                @click="clickedTab = 8"
              >
                <template #title>
                  Pdf evaluators
                </template>
                <PdfEvaluators v-if="activeTab == 8" />
              </b-tab>
              <b-tab
                :active="activeTab == 995"
                @click="clickedTab = 995"
              >
                <template #title>
                  Offered
                </template>
                <AllApplicants
                  v-if="activeTab == 995"
                  :status="995"
                  :custom-actions="true"
                />
              </b-tab>
            </b-tabs>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import Form from 'vform'
import {
  BTab, BTabs, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import AllApplicants from '../academy-call/components/AllApplicants.vue'
import Acta from '../academy-call/components/Acta.vue'
import NotElegible from '../academy-call/components/NotElegible.vue'
import Retrieves from '../academy-call/components/Retrieves.vue'
import Rankeds from '../academy-call/components/Rankeds.vue'
import PdfEvaluators from '../academy-call/components/PdfEvaluators.vue'

export default {
  components: {
    AllApplicants,
    Acta,
    BTabs,
    BTab,
    NotElegible,
    Retrieves,
    BDropdown,
    BDropdownItem,
    Rankeds,
    PdfEvaluators,
  },
  data() {
    return {
      sortedField: 'Name',
      defaultFields: [
        {
          name: 'Researcher',
          checked: true,
          order: 1,
        },
      ],
      sortMapping: {
        Researcher: 'user.name',
      },
      clickedTab: null,
      convoId: this.$route.params.id,
      sections: {},
      loading: false,
      files: [],
      form: new Form({ data: {} }),
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      sended: 'convo/sended',
      loggedUser: 'auth/admin',
    }),
    activeTab() {
      return this.clickedTab ?? (this.loggedUser.call_status ?? 0)
    },
    areas() {
      if (this.convo.areas) {
        // return this.convo.areas.map(e => e.code)
        return this.convo.areas.filter(e => {
          if (!e.parent_id) {
            return true
          }
          return false
        }).map(e => e.code)
      }

      return []
    },
  },
  async mounted() {
    if (this.convoId) {
      await this.$store.dispatch('convo/fetchSpecific', this.convoId)
    }
    // eslint-disable-next-line no-undef
    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    updateCallStatus() {
      this.$store.dispatch('users/updateStatus', { id: this.loggedUser.id, status: this.loggedUser.call_status })
    },
  },
}
</script>
